import ButtonLink from "components/ButtonLink";
import Link from "components/Link";
import PaginationNavigation from "components/PaginationNavigation";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { FieldLocale } from "utils/common/locale";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";

interface Props {
  data: {
    allDatoCmsNews: {
      nodes: any[];
    };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: any[];
    pagePath: string;
    currentPageIndex: number;
    selectedTag: string;
    allTags: string[];
    numOfPages: number;
    prevPage: string;
    nextPage: string;
  };
}

/**
 * News page wrapper
 * Just to allow useContext usage inside
 */
const NewsPage: React.SFC<Props> = ({ data, pageContext }) => {
  return <News data={data} pageContext={pageContext} />;
};

const News = ({ data, pageContext }: Props) => {
  const {
    locale,
    defaultLocale,
    currentPageIndex,
    selectedTag,
    allTags,
    numOfPages,
    prevPage,
    nextPage,
    pagePath,
  } = pageContext;

  const { formatMessage } = useTranslation();

  const {
    allDatoCmsNews: { nodes: news },
  } = data;

  const isFirst = currentPageIndex === 1;
  const isLast = currentPageIndex === numOfPages;

  const seoTitle =
    selectedTag && selectedTag != "all"
      ? `${formatMessage({ id: "SEO.newsTitle" })} - ${selectedTag}`
      : formatMessage({ id: "SEO.newsTitle" });

  return (
    <Wrapper>
      <SEO
        title={seoTitle}
        description={formatMessage({ id: "SEO.newsDescription" })}
        link={
          isFirst
            ? [{ rel: "canonical", href: "https://ruedesmille.com" + pagePath }]
            : []
        }
        meta={!isFirst ? [{ name: "robots", content: "noindex, follow" }] : []}
      />
      <Title>News</Title>
      <Categories>
        <CategoryLabel>
          <FormattedMessage id="news.category" defaultMessage="Category" />:
        </CategoryLabel>
        <CategoriesList>
          {allTags.map((tag) => (
            <Link
              to={`${locale === defaultLocale ? "" : `/${locale}`}/news/${
                tag === "all" ? "" : kebabCase(tag) + "/"
              }`}
              key={tag}
            >
              <Category isSelected={tag === selectedTag}>
                {tag === "all" ? (
                  <FormattedMessage id="news.all" defaultMessage="All" />
                ) : (
                  <span>{tag}</span>
                )}
              </Category>
            </Link>
          ))}
        </CategoriesList>
      </Categories>
      <ArticlesList>
        {news.map((article, index) => (
          <Article
            key={article.id}
            imageAlignment={index % 2 === 0 ? "left" : "right"}
          >
            <ArticleImageContainer
              imageAlignment={index % 2 === 0 ? "left" : "right"}
            >
              <ArticleBackgroundBox
                imageAlignment={index % 2 === 0 ? "left" : "right"}
              />
              {!!article.featuredImage && (
                <Link
                  to={`${locale === defaultLocale ? "" : `/${locale}`}/news/${
                    article._allHandleLocales.find(
                      (h: FieldLocale<string>) => h.locale === locale
                    ).value
                  }`}
                >
                  <ArticleImage src={article.featuredImage.url} />
                </Link>
              )}
            </ArticleImageContainer>
            <ArticleContent imageAlignment={index % 2 === 0 ? "left" : "right"}>
              <ArticleTitle>
                {
                  article._allTitleLocales.find(
                    (t: FieldLocale<string>) => t.locale === locale
                  ).value
                }
              </ArticleTitle>
              <ArticleDate>
                <FormattedDate
                  day="2-digit"
                  month="long"
                  year="numeric"
                  value={article.date}
                />
              </ArticleDate>

              <ArticlePreview
                imageAlignment={index % 2 === 0 ? "left" : "right"}
                dangerouslySetInnerHTML={{
                  __html: article.excerptNode.childMarkdownRemark.html,
                }}
              />

              <ButtonLink
                to={`${locale === defaultLocale ? "" : `/${locale}`}/news/${
                  article._allHandleLocales.find(
                    (h: FieldLocale<string>) => h.locale === locale
                  ).value
                }`}
              >
                <FormattedMessage
                  id="news.discoverMore"
                  defaultMessage="Discover more"
                />
              </ButtonLink>
            </ArticleContent>
          </Article>
        ))}
        <PaginationNavigation
          isFirst={isFirst}
          isLast={isLast}
          prevPage={prevPage}
          nextPage={nextPage}
          prevPageMessage={formatMessage({ id: "news.older" })}
          nextPageMessage={formatMessage({ id: "news.newer" })}
        />
      </ArticlesList>
    </Wrapper>
  );
};

export const query = graphql`
  query newsQuery($locale: String, $newsIds: [String]) {
    allDatoCmsNews(
      filter: { id: { in: $newsIds }, locale: { eq: $locale } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        tags
        date
        featuredImage {
          url
        }
        _allTitleLocales {
          locale
          value
        }
        _allHandleLocales {
          locale
          value
        }
        _allTagsLocales {
          locale
          value
        }
        _allBodyLocales {
          locale
          value {
            __typename
            ... on DatoCmsRte {
              textNode {
                childMarkdownRemark {
                  html
                }
              }
              textAlignment
            }
            ... on DatoCmsImage {
              images {
                url
              }
            }
            ... on DatoCmsImageText {
              textNode {
                childMarkdownRemark {
                  html
                }
              }
              images {
                url
              }
              imageAlignment
            }
          }
        }
        excerptNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 30px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Categories = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #999999;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;
  }
`;

const CategoryLabel = styled.span`
  text-transform: uppercase;
  @media (max-width: 650px) {
    padding-bottom: 10px;
  }
`;

const CategoriesList = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const Category = styled.div`
  padding: 2px 20px 3px 20px;
  margin-left: 10px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.background2};
  border: 2px solid rgba(151, 123, 43, 0.2);
  color: ${({ theme }) => theme.colors.main};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s all;
  @media (max-width: 650px) {
    margin: 0 6px 6px 0;
  }
  &:hover {
    border: 2px solid rgba(151, 123, 43, 1);
  }
  ${({ isSelected }: { isSelected: boolean }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.main};
      color: #fff;
      border: 2px solid ${({ theme }) => theme.colors.main};
    `}
`;

const ArticlesList = styled.div`
  margin: 60px 0;
  position: relative;
`;

const Article = styled.div`
  margin: 80px 0 120px 0;
  display: flex;
  flex-direction: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "row" : "row-reverse"};
  position: relative;
  @media (max-width: 950px) {
    flex-direction: column;
    margin: 80px 0 80px 0;
  }
`;

const ArticleImageContainer = styled.div`
  width: 60%;
  flex: 0 0 60%;
  position: relative;
  @media (max-width: 1280px) {
    padding: ${({ imageAlignment }: { imageAlignment: string }) =>
      imageAlignment === "left" ? "0 0 0 20px" : "0 20px 0 0"};
  }
  @media (max-width: 950px) {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 15%;
  }
  @media (max-width: 600px) {
    padding: 0 10%;
  }
  @media (max-width: 450px) {
    padding: 0;
  }
`;

const ArticleBackgroundBox = styled.div`
  position: absolute;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.background2};
  height: calc(100% + 80px);
  width: calc((100vw - 1200px) / 2 + (100% - 200px));
  padding: 40px;
  top: -40px;
  right: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "200px" : "auto"};
  left: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "auto" : "200px"};
  @media (max-width: 1240px) {
    width: calc(100% - 100px + 20px);
  }
  @media (max-width: 950px) {
    width: calc(60% + 20px);
    right: ${({ imageAlignment }: { imageAlignment: string }) =>
      imageAlignment === "left" ? "40%" : "auto"};
    left: ${({ imageAlignment }: { imageAlignment: string }) =>
      imageAlignment === "left" ? "auto" : "40%"};
  }
`;

const ArticleImage = styled.img`
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const ArticleContent = styled.div`
  width: 40%;
  flex: 0 0 40%;
  padding: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "40px 0 40px 80px" : "40px 80px 40px 0"};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "flex-end" : "flex-start"};
  text-align: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "right" : "left"};
  @media (max-width: 1240px) {
    padding: ${({ imageAlignment }: { imageAlignment: string }) =>
      imageAlignment === "left"
        ? "40px 40px 40px 60px"
        : "40px 60px 40px 40px"};
  }
  @media (max-width: 950px) {
    width: 100%;
    flex: 0 0 100%;
    padding: 80px 20px 0px 20px;
    text-align: center;
  }
  @media (max-width: 450px) {
    padding: 80px 0 0 0;
  }
`;

const ArticleTitle = styled.h2`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  padding-bottom: 20px;
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const ArticleDate = styled.div`
  font-size: 14px;
  color: #706f6f;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
`;

const ArticlePreview = styled.div`
  color: #706f6f;
  margin-bottom: 20px;
  text-align: ${({ imageAlignment }: { imageAlignment: string }) =>
    imageAlignment === "left" ? "right" : "left"};
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.6em;
    margin: 0;
    padding-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
  }
  pre {
    white-space: normal;
  }
`;

export default NewsPage;
