import React from "react";
import styled from "styled-components";
import Link from "./Link";

interface Props {
  isLast: boolean;
  isFirst: boolean;
  prevPage: string;
  nextPage: string;
  prevPageMessage: string;
  nextPageMessage: string;
}

export default function PaginationNavigation({
  isLast,
  isFirst,
  prevPage,
  nextPage,
  prevPageMessage,
  nextPageMessage,
}: Props) {
  return (
    <Navigation>
      {!isLast && (
        <Link to={nextPage} rel="next">
          {nextPageMessage}
        </Link>
      )}
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          {prevPageMessage}
        </Link>
      )}
    </Navigation>
  );
}

const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 80px;
  [rel="next"],
  [rel="prev"] {
    text-decoration: none;
    transition: all 0.3s;
    margin-top: 20px;
    width: 100%;
    transition: 0.4s;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: ${({ theme }) => theme.colors.main};

    &:hover {
      cursor: pointer;
      &::after {
        margin-left: 10px;
        margin-right: -10px;
      }
      &::before {
        margin-right: 10px;
        margin-left: -10px;
      }
    }
  }
  [rel="prev"] {
    text-align: right;
    &::after {
      visibility: visible;
      content: "→";
      display: inline-block;
      font-size: 16px;
      padding-left: 5px;
      transition: 0.4s;
      font-family: system-ui;
      color: ${({ theme }) => theme.colors.main};
      @media (max-width: 500px) {
        font-size: 13px;
      }
    }
  }
  [rel="next"] {
    &::before {
      visibility: visible;
      content: "←";
      display: inline-block;
      font-size: 16px;
      padding-right: 5px;
      transition: 0.4s;
      font-family: system-ui;
      color: ${({ theme }) => theme.colors.main};
    }
    &::after {
      @media (max-width: 500px) {
        visibility: visible;

        display: inline-block;
        font-size: 16px;
        padding-right: 5px;
        transition: 0.4s;
        font-family: system-ui;
        color: ${({ theme }) => theme.colors.main};
        font-size: 13px;
      }
    }
  }
`;
